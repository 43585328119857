import React from 'react'
import Layout from '../../components/layout'
import FullWidth from '../../components/fullWidth'
import IntegrationValues from '../../components/IntegrationValues'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/seo'
import { TalkWithFoundersCta } from '../../components/SignUp'
import classnames from 'classnames'
import shareImage from '../../images/integrations/wfirma/share-image.png'
import wfirmaLogo from '../../images/logos/wfirma.svg'

import * as heroStyle from '../../components/webreport/ReportHero.module.css'
import * as style from './wfirma.module.css'

const Integrations = () => (
  <Layout nakedBar trialCta>
    <Seo
      title="Integracja wFirma dla SaaS"
      description={
        'Integracja Probe + wFirma. Bezproblemowa analityka na podstawie faktur w wFirma.'
      }
      keywords={['integracja', 'wfirma', 'saas', 'mrr', 'bi', 'analityka']}
      twitterImg={shareImage}
      mainpageImg={shareImage}
    />
    <FullWidth>
      <div className={heroStyle.heroContent}>
        <div className={classnames(heroStyle.heroCopy, style.heroCopyWidth)}>
          <h1 className={heroStyle.title}>
            Analityka biznesowa dla użytkowników wFirma
          </h1>
          <p className={heroStyle.subtitle}>
            Używasz wFirmy do wystawiania faktur klientom Twojego biznesu? Za
            pomocą integracji z Wfirmą zobaczysz w Probe wszystkie potrzebne
            metryki.
          </p>
          <p className={heroStyle.subtitle}>
            Poznasz najbardziej podstawowe informacje jak MRR czy Churn, ale też
            te bardziej zaawansowane: kohorty retencji MRRu i segmentacje na
            podstawie dowolnych atrybutów. W sumie ponad 50 metryk SaaS i nie
            tylko.
          </p>
          <div>
            <TalkWithFoundersCta />
          </div>
        </div>
        <div className={classnames(heroStyle.heroImage, style.heroImageWidth)}>
          <div className={style.wfirmaLogoWrapper}>
            <img src={wfirmaLogo} width="200px" alt="wFirma logo" />
          </div>
        </div>
      </div>
      <Testimonial
        person="Aleksander Gorczyca"
        title="Chief Financial Officer"
        url="https://callpage.io"
      >
        "Za pomocą Probe liczymy i wizualizujemy metryki dla pracowników i
        inwestorów. Używamy tych danych aby lepiej zrozumieć i rozwijać nasz
        biznes."
      </Testimonial>
    </FullWidth>
    <IntegrationValues />
  </Layout>
)

export default Integrations
